@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name) {
      @content;
    }
  }
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(50%);
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin font-size($size, $base: 1920) {
  $min-width: 1280px;
  $max-width: 1920px;

  font-size: #{$size / $base * 100}vw;

  @include media-breakpoint-between(md, $min-width) {
    font-size: #{$size * (strip-unit($min-width) / $base)}px;
  }

  @include media-breakpoint-up($max-width) {
    font-size: #{$size * (strip-unit($max-width) / $base)}px;
  }
}
