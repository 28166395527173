@charset 'utf-8';

// prerequisites
@import 'abstracts/*';

// partials
@import '_*';

.sec {
  padding: 50px 0;

  @include media-breakpoint-up(md) {
    padding: 75px 0 125px;
  }

  &__heading {
    position: relative;
    padding: 0 0 0 20px;
    font-family: $font-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      padding: 0 0 0 25px;
      font-size: 30px;
    }

    &::before {
      position: absolute;
      top: 5px;
      left: 0;
      width: 3px;
      height: 20px;
      content: '';
      background: $light-green;

      @include media-breakpoint-up(md) {
        top: 7px;
        width: 6px;
        height: 29px;
      }
    }
  }

  &__text {
    margin: 35px 0 0;
    font-family: $font-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;

    @include media-breakpoint-up(md) {
      margin: 50px 0 0;
      font-size: 16px;
    }
  }

  &__button {
    width: 100%;
    margin: 50px 0 0;

    @include media-breakpoint-up(md) {
      width: 520px;
      margin: 100px 0 0;
    }

    .c-pageButton {
      font-size: 13px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }

      + .c-pageButton {
        margin: 30px 0 0;

        @include media-breakpoint-up(md) {
          margin: 50px 0 0;
        }
      }
    }
  }
}
