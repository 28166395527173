@charset "UTF-8";
.sec {
  padding: 50px 0;
}
@media (min-width: 768px) {
  .sec {
    padding: 75px 0 125px;
  }
}
.sec__heading {
  position: relative;
  padding: 0 0 0 20px;
  font-family: "Yu Mincho", "游明朝体", serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .sec__heading {
    padding: 0 0 0 25px;
    font-size: 30px;
  }
}
.sec__heading::before {
  position: absolute;
  top: 5px;
  left: 0;
  width: 3px;
  height: 20px;
  content: "";
  background: #238184;
}
@media (min-width: 768px) {
  .sec__heading::before {
    top: 7px;
    width: 6px;
    height: 29px;
  }
}
.sec__text {
  margin: 35px 0 0;
  font-family: "Yu Mincho", "游明朝体", serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .sec__text {
    margin: 50px 0 0;
    font-size: 16px;
  }
}
.sec__button {
  width: 100%;
  margin: 50px 0 0;
}
@media (min-width: 768px) {
  .sec__button {
    width: 520px;
    margin: 100px 0 0;
  }
}
.sec__button .c-pageButton {
  font-size: 13px;
}
@media (min-width: 768px) {
  .sec__button .c-pageButton {
    font-size: 18px;
  }
}
.sec__button .c-pageButton + .c-pageButton {
  margin: 30px 0 0;
}
@media (min-width: 768px) {
  .sec__button .c-pageButton + .c-pageButton {
    margin: 50px 0 0;
  }
}